import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu'; // For the menu icon
import { Link } from "react-router-dom";

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: 'Belleza, Cursive',
  },
});

const menuItems = [
  {
    title: "Bemutatkozás",
    path: "/introduction",
  },
  {
    title: "Árak",
    path: "/prices",
  },
  {
    title: "Kapcsolat",
    path: "/contact",
  },
  {
    title: "Galéria",
    path: "/gallery",
  },
  // ... add other menu items as needed
];

const Layout = ({ children }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ backgroundColor: isMobile ? '#FA9884' : 'white', color: isMobile ? 'white' : 'black' }}>
          <Toolbar>
            {isMobile ? (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                >
                  <List>
                    {menuItems.map((item) => (
                      <ListItem button key={item.title} component={Link} to={item.path} onClick={closeDrawer}>
                        <ListItemText primary={item.title} />
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </>
            ) : (
              menuItems.map((item) => (
                <Button
                  key={item.title}
                  color="inherit"
                  component={Link}
                  to={item.path}
                  sx={{ color: 'black', fontWeight: 'bold', fontSize: '12px' }}
                >
                  {item.title}
                </Button>
              ))
            )}
          </Toolbar>
        </AppBar>
        <Toolbar id="back-to-top-anchor" />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            p: 0,
            pt: '0px',
          }}
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;