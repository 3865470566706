import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, useMediaQuery } from "@mui/material";

const prices = [
  { item: '60 Perc Teljes Testmasszázs', price: '10.000.- Ft' },
  { item: '30 Perc Hátmasszázs', price: '7000.- Ft' },
  // { item: '60 Perc Nyirokmasszázs', price: '13.000.- Ft' },
];

const leases = [
    { item: '5 * 60 Perc Teljes Testmasszázs', price: '45.000.- Ft' },
    { item: '5 * 30 Perc Hátmasszázs', price: '30.000.- Ft' },
];

const PricesPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const commonBoxStyle = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    maxWidth: '1080px',
    zIndex: 2,
    ...(isMobile && { bottom: '10%', width: '95%', fontSize: '1.2rem' }),
  };

  return (
    <>
      <Box sx={{
        overflowX: 'hidden', // Prevent horizontal scrolling
      }}>
        {/* Background Image */}
        {!isMobile && (
        <Box sx={{
          position: 'fixed',
          width: '100vw',
          height: '450px',
          backgroundImage: 'url("./Images/prices_picture.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: "60% 40%",
          backgroundRepeat: 'no-repeat',
          zIndex: -1
        }}>
        </Box>
        )}

        {/* Price List Container */}
        <Box sx={{ ...commonBoxStyle, bottom: isMobile ? '55%' : '40%' }}>
          <TableContainer component={Paper} elevation={6}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Masszázs Típus</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>Ár</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prices.map((price) => (
                  <TableRow
                    key={price.item}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {price.item}
                    </TableCell>
                    <TableCell align="right">{price.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Lease List Container */}
        <Box sx={{ ...commonBoxStyle, bottom: isMobile ? '25%' : '25%' }}>
          <TableContainer component={Paper} elevation={6}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Bérlet Típus</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>Ár</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leases.map((price) => (
                  <TableRow
                    key={price.item}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {price.item}
                    </TableCell>
                    <TableCell align="right">{price.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}

export default PricesPage;