import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const introductionText = `
  Csak hölgyeket fogadok!
  
  Csicsáky Dorottyának hívnak, 2010-Ben végeztem gyógymasszőrként,
  továbbá van képesítésem svéd, nyirok, talp, illetve shiatsu masszázsokból.
  Rendkívül fontosnak tartom a test-lélek-szellem egységes működését,
  ezért kezeléseim során nem kizárólag az izmok és ízületek kerülnek előtérbe, hanem a vendég aktuális lelki állapotát is figyelembe veszem.

  Masszírozás által az ember izmai tónusossá válnak, lazulnak és beindul a test öngyógyító mechanizmusa.

  Várom szeretettel!
  
`;

const IntroductionPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box sx={{
        overflowX: 'hidden',
      }}>
        {/* Background Image */}
          {!isMobile && (
        <Box sx={{
          width: '100vw',
          height: '450px',
          backgroundImage: 'url("./Images/introduction_picture.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1
        }}>
        </Box>
          )}

        {/* Content Container */}
        <Box sx={{
          marginTop: isMobile ? '3rem' : 0,
          width: isMobile ? '100%' : '60%',
            transform: isMobile ? 'translate(0, 0)' : 'translate(30%, 0%)',
          maxWidth: '1000px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: isMobile ? 'center' : 'center',
          zIndex: 2,
          padding: isMobile ? '20px' : '0',
        }}>
          {/* Image */}
          <Box
            component="img"
            src="./Images/dori.jpg"
            alt="Dori's Photo"
            sx={{
              width: isMobile ? '60%' : '100%',
              maxWidth: isMobile ? '300px' : '250px',
              height: 'auto',
              marginBottom: isMobile ? '20px' : '0',
            }}
          />
          {/* Introduction Text */}
          <Typography variant="body1" sx={{
            fontSize: isMobile ? '16px' : '20px',
            whiteSpace: 'pre-line',
            marginLeft: isMobile ? '0' : '150px',
          }}>
              {introductionText}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default IntroductionPage;