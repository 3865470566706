import React from 'react';
import { Box, Typography } from '@mui/material';

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Typography variant="h4" component="h1">
        404 Not Found
      </Typography>
      <Typography variant="body1">
        Ez az oldal nem létezik.
      </Typography>
    </Box>
  );
};

export default NotFoundPage;