import React from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import IntroductionPage from "./Pages/IntroductionPage";
import PricesPage from "./Pages/PricesPage";
import ContactPage from "./Pages/ContactPage";
import NotFoundPage from "./Pages/NotFoundPage";
import Layout from "./Components/Layout";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HashRouter>
        <Layout>
            <Routes>
                <Route path={"/"} element={<App/>}/>
                <Route path={"/introduction"} element={<IntroductionPage/>}/>
                <Route path={"/prices"} element={<PricesPage/>}/>
                <Route path={"/contact"} element={<ContactPage/>}/>
                <Route path={"*"} element={<NotFoundPage/>} />
            </Routes>
        </Layout>
    </HashRouter>
);

reportWebVitals();