import React from 'react';
import { Box, Typography, Paper, Grid, useTheme, useMediaQuery } from "@mui/material";

const ContactPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box sx={{ overflowX: 'hidden' }}>
        {!isMobile && (
          <Box sx={{
            position: 'fixed',
            width: '100vw',
            height: '450px',
            backgroundImage: 'url("./Images/contact_picture.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: -1
          }}/>
        )}

        <Grid container spacing={2} sx={{
          position: 'absolute',
          top: isMobile ? '50%' : '70%',
          left: 0,
          right: 0,
          transform: 'translateY(-50%)',
          zIndex: 1,
          justifyContent: 'center',
          px: isMobile ? 2 : 0,
        }}>

          {/* Contact Details */}
          <Grid item xs={12} md={5} sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Paper elevation={3} sx={{
              p: isMobile ? 2 : 3,
              width: isMobile ? '90%' : '35%',
              height: 'auto',
              bgcolor: 'background.paper',
              margin: 'auto',
            }}>
              <Typography variant="h5">Csak hölgyeket fogadok!</Typography>
              <Typography variant="h6">Kapcsolat</Typography>
              <Typography variant="body1">Ágoston utca 6.</Typography>
              <Typography variant="body1">Budapest, 1035</Typography>
              <Typography variant="body1">Email: dorottyacsicsaky@gmail.com</Typography>
            </Paper>
          </Grid>

          {/* Google Map */}
          <Grid item xs={12} md={5} sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Box sx={{
              width: '100%',
              height: isMobile ? '300px' : '600px',
              bgcolor: 'background.paper',
              backgroundImage: 'url("./Images/map.jpg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              margin: 'auto',
            }}/>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ContactPage;