import React from 'react';
import { Box, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";

function App() {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: "background.default",
        p: 3,
        margin: "8 auto",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h5"
        fontWeight={600}
        noWrap
        component="div"
        marginBottom="2rem"
      >
        Dori's Page
      </Typography>
      {/* Redirect to the Introduction Page */}
      <Navigate to="/introduction" />
    </Box>
  );
}

export default App;